<template>
  <div class="assessment vh-100">
    <v-container class="py-0 px-0 pb-10" fluid>
      <v-alert
        v-if="!$online"
        dense
        tile
        color="gray"
        icon="mdi-alert-circle-outline"
        class="alert-offline text-headline"
      >
        {{ $t("t_no_connection") }}
      </v-alert>

      <v-form>
        <v-card flat tile style="background: #303030">
          <v-card-text class="d-flex pb-0">
            <p class="text-h6 mb-0">Risk Plan Assessment for Henderson Mine Blasting</p>
            <!-- <v-text-field class="step-input pt-1" filled outlined :label="`Task ${stepCount + 1}`" v-model=".name" /> -->
          </v-card-text>
        </v-card>

        <v-stepper v-model="stepper" vertical tile flat class="rounded-0">
          <v-stepper-step :complete="stepper > 1" step="1">
            <h3><a v-if="stepper > 1" @click="stepper = 1">Job Tasks</a><span v-else>Job Tasks</span></h3>
            <span class="subtitle-2 font-weight-regular pt-1"
              >Break the job into a sequence of steps. Each of the steps should accompany some major task.</span
            >
          </v-stepper-step>

          <v-stepper-content step="1">
            <div>
              <div v-for="(s, stepCount) in steps" :key="'step-' + stepCount">
                <div>
                  <v-text-field
                    class="step-input pt-1"
                    filled
                    outlined
                    :label="`Task ${stepCount + 1}`"
                    v-model="steps[stepCount].name"
                  />
                </div>
                <div class="subtitle-2 mb-2">Identify Hazards that exist for this step</div>
                <v-autocomplete
                  multiple
                  :items="allHazardSubTypes"
                  item-text="text"
                  item-value="value"
                  label="Select Hazards..."
                  chips
                  deletable-chips
                  @change="setSelectHazard(stepCount, $event)"
                  :value="hazardValues.length ? hazardValues : null"
                >
                </v-autocomplete>
                <div>
                  <v-btn text x-small @click="showHazardList = showHazardList == stepCount ? null : stepCount"
                    ><v-icon v-if="showHazardList == stepCount">mdi-chevron-up</v-icon
                    ><v-icon v-else>mdi-chevron-down</v-icon> Hazard List</v-btn
                  >
                </div>
                <div v-if="showHazardList == stepCount">
                  <div v-for="(hazard, typeCount) in hazards" :key="'h-' + typeCount">
                    <div>
                      <v-checkbox
                        hide-details
                        class="my-1 text-capitalize"
                        :label="hazard.type"
                        @change="setHazard(stepCount, hazard.type, $event)"
                        :input-value="steps[stepCount].hazards.some((v) => v.type == hazard.type)"
                      >
                      </v-checkbox>
                      <div v-if="hazard && hazard.type && s.hazards.some((h) => h.type == hazard.type)">
                        <span
                          v-for="(subType, index) in s.hazards.find((h) => h.type == hazard.type).subTypes"
                          :key="'typedItem-' + index"
                        >
                          <v-chip
                            class="ma-1"
                            close
                            color="grey lighten-1"
                            label
                            outlined
                            small
                            @click:close="setHazardType(stepCount, hazard.type, subType, false)"
                          >
                            {{ subType.name }}
                          </v-chip>
                        </span>
                        <v-chip
                          class="ma-1"
                          color="primary"
                          label
                          small
                          @click="
                            steps[stepCount].drawer = true;
                            drawerHazardIndex = typeCount;
                          "
                        >
                          <v-icon class="mr-1" small>mdi-plus</v-icon> Add hazards
                        </v-chip>
                      </div>
                    </div>
                  </div>
                </div>
                <v-navigation-drawer v-model="steps[stepCount].drawer" fixed temporary right>
                  <h3 class="pa-3 pb-1 text-capitalize" style="background: #222">
                    {{ hazards[drawerHazardIndex].type }}
                  </h3>

                  <div class="mt-3">
                    <div
                      class="ml-3"
                      v-for="(subType, subTypeCount) in hazards[drawerHazardIndex].subTypes"
                      :key="'sub-' + subTypeCount"
                    >
                      <v-checkbox
                        hide-details
                        style="font-size: 14px"
                        class="my-2 py-0 text-capitalize"
                        :label="subType"
                        @change="setHazardType(stepCount, hazards[drawerHazardIndex].type, subType, $event)"
                        :input-value="
                          steps[stepCount].hazards.find((h) => h.type == hazards[drawerHazardIndex].type)
                            ? steps[stepCount].hazards
                                .find((h) => h.type == hazards[drawerHazardIndex].type)
                                .subTypes.some((v) => v.name == subType)
                            : null
                        "
                      ></v-checkbox>
                    </div></div
                ></v-navigation-drawer>
                <v-divider class="my-4"></v-divider>
              </div>
              <div class="d-flex">
                <v-btn small color="primary" @click="addStep()">Add task </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="stepper = 2"> Continue </v-btn>
              </div>
            </div>
          </v-stepper-content>

          <v-stepper-step :complete="stepper > 2" step="2">
            <h3><a v-if="stepper > 2" @click="stepper = 2">Assess hazards</a><span v-else>Assess hazards</span></h3>
            <span class="subtitle-2 font-weight-regular pt-1"
              >Determine the likelihood and consequences of the hazards associated with the tasks.</span
            >
          </v-stepper-step>

          <v-stepper-content step="2">
            <div v-for="(step, stepIndex) in steps" :key="'stepItem-' + stepIndex">
              <div class="overline" style="line-height: 12px">Step {{ stepIndex + 1 }}</div>
              <div>{{ step.name }}</div>
              <v-divider></v-divider>
              <div class="pt-3">
                <div v-for="(hazard, hazardIndex) in step.hazards" :key="'hazardItem-' + hazardIndex">
                  <div v-for="(type, subTypeIndex) in hazard.subTypes" :key="'typedItem-' + subTypeIndex">
                    <span class="text-capitalize">
                      <v-icon color="orange darken-2 mr-1" style="font-size: 20px; margin-top: -2px"
                        >mdi-alert-box</v-icon
                      >
                      {{ type.name }} : {{ hazard.type }}</span
                    >
                    <div class="ml-1">
                      <!-- <v-radio-group
                        v-model="steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].likelihood"
                        dense
                        hide-details
                      >
                        <v-radio :label="'Rare'" :value="1" />
                        <v-radio :label="'Unlikely'" :value="2" />
                        <v-radio :label="'Possible'" :value="3" />
                        <v-radio :label="'Likely'" :value="4" />
                        <v-radio :label="'Almost certain'" :value="5" />
                        <template v-slot:label>
                          <span class="body-1">Likelihood of Occurrence</span>
                        </template>
                      </v-radio-group> -->
                      <v-divider class="my-3"></v-divider>
                      Likelihood of Occurrence:
                      <span
                        :style="
                          'color:' +
                          setTrackColor(steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].likelihood)
                        "
                      >
                        {{
                          likelihoodThumbValue[steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].likelihood]
                        }}
                      </span>

                      <v-slider
                        v-model="steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].likelihood"
                        step="1"
                        :max="4"
                        ticks
                        tick-size="3"
                        :tick-labels="['Rare', 'Unlikely', 'Possible', 'Likely', 'Virtually']"
                        track-color="primary"
                        track-size=""
                        :track-fill-color="
                          setTrackColor(steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].likelihood)
                        "
                      >
                      </v-slider>

                      <v-radio-group
                        v-model="steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].impact"
                        dense
                      >
                        <v-radio :label="'Minor'" :value="1" />
                        <v-radio :label="'Medium'" :value="2" />
                        <v-radio :label="'Serious'" :value="3" />
                        <v-radio :label="'Major'" :value="4" />
                        <v-radio :label="'Catastrophic'" :value="5" />
                        <template v-slot:label>
                          <span class="body-1">Consequence of Occurrence</span>
                        </template>
                      </v-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <v-btn color="primary" @click="stepper = 3"> Continue </v-btn>
            <v-btn text @click="stepper = 1"> Back </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="stepper > 3" step="3">
            <h3>
              <a v-if="stepper > 3" @click="stepper = 3">Controls &amp; Recommendations</a
              ><span v-else>Controls &amp; Recommendations</span>
            </h3>
            <div class="subtitle-2 font-weight-regular pt-1">
              Decide what actions are necessary to eliminate, control, or minimize hazards that could lead to accidents,
              injuries, damage to the environment, or illness.

              <v-btn class="mx-4" color="grey" x-small icon @click="dialogInfo = true"
                ><v-icon small color="yellow darken-2" class="mr-1">mdi-information</v-icon>Info</v-btn
              >
            </div>
          </v-stepper-step>

          <v-stepper-content step="3">
            <div class="mt-3 mb-5" v-for="(step, stepIndex) in steps" :key="'stepItem-' + stepIndex">
              <div class="overline" style="line-height: 12px">Step {{ stepIndex + 1 }}</div>
              <div class="py-2">{{ step.name }}</div>
              <v-divider></v-divider>
              <div class="pt-3">
                <div v-for="(hazard, hazardIndex) in step.hazards" :key="'hazardItem-' + hazardIndex">
                  <div v-for="(type, subTypeIndex) in hazard.subTypes" :key="'typedItem-' + subTypeIndex">
                    <span class="text-capitalize">
                      <v-icon color="orange darken-2 mr-1" style="font-size: 20px; margin-top: -2px"
                        >mdi-alert-box</v-icon
                      >
                      {{ type.name }} : {{ hazard.type }}</span
                    >
                    <div class="ml-6 mt-2 mb-4">
                      <v-progress-linear
                        rounded
                        striped
                        class="my-2"
                        :color="
                          setTrackColor(steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].likelihood) +
                          ' darken-4'
                        "
                        :value="
                          steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].likelihood != 0
                            ? Math.ceil(
                                (steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].likelihood / 5) * 100,
                              )
                            : 5
                        "
                        height="15"
                      >
                        <span class="overline">Likelihood</span>
                      </v-progress-linear>

                      <v-progress-linear
                        rounded
                        striped
                        class="my-2"
                        :color="
                          setTrackColor(steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].impact) +
                          ' darken-4'
                        "
                        :value="
                          Math.ceil((steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].impact / 5) * 100)
                        "
                        height="15"
                      >
                        <span class="overline">Impact</span>
                      </v-progress-linear>

                      <v-select
                        class="mt-3"
                        dense
                        hide-details
                        filled
                        outlined
                        :items="riskControls"
                        v-model="steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].controls"
                        label="Select a risk management control"
                      />

                      <v-text-field
                        hide-details
                        label="Specific actions to minimize risk"
                        class="step-input pt-1 mt-2"
                        filled
                        outlined
                        v-model="steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].actions"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <v-btn color="primary" @click="stepper = 4"> Continue </v-btn>
            <v-btn text @click="stepper = 2"> Back </v-btn>
          </v-stepper-content>

          <v-stepper-step step="4">
            <h3>Summary and Review</h3>
          </v-stepper-step>
          <v-stepper-content step="4">
            <div v-for="(step, stepIndex) in steps" :key="'stepItem-' + stepIndex">
              <div class="overline" style="line-height: 12px">Step {{ stepIndex + 1 }}</div>
              <div>{{ step.name }}</div>
              <v-divider></v-divider>
              <div class="pt-3">
                <div v-for="(hazard, hazardIndex) in step.hazards" :key="'hazardItem-' + hazardIndex">
                  <div v-for="(type, subTypeIndex) in hazard.subTypes" :key="'typedItem-' + subTypeIndex">
                    <span class="text-capitalize">
                      <v-icon color="orange darken-2 mr-1" style="font-size: 20px; margin-top: -2px"
                        >mdi-alert-box</v-icon
                      >
                      {{ type.name }} : {{ hazard.type }}</span
                    >
                    <div class="ml-6 mt-2">
                      <v-progress-linear
                        rounded
                        striped
                        class="my-2"
                        color="light-green darken-3"
                        :value="
                          Math.ceil((steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].likelihood / 5) * 100)
                        "
                        height="15"
                      >
                        <span class="overline">Likelihood</span>
                      </v-progress-linear>

                      <v-progress-linear
                        rounded
                        striped
                        class="my-2"
                        color="orange darken-4"
                        :value="
                          Math.ceil((steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].impact / 5) * 100)
                        "
                        height="15"
                      >
                        <span class="overline">Impact</span>
                      </v-progress-linear>
                      <label class="overline">Risk Controls</label>
                      <div class="mb-3">
                        {{ steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].controls }}
                      </div>
                      <label class="overline">Actions to reduce risk</label>
                      <div class="mb-3">{{ steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].actions }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <v-btn color="primary" @click="stepper = 1"> Submit Risk Assessment </v-btn>
            <v-btn text @click="stepper = 3"> Back </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-form>
    </v-container>
    <v-dialog v-model="dialogInfo" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="primary" class="rounded-0 pr-4">
          <v-toolbar-title>More Info</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn icon @click="dialogInfo = false"> <v-icon class="mr-2">mdi-close</v-icon> Close </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div>
          <v-container>
            <v-card>
              <v-card-title>Risk Management Controls</v-card-title>
              <v-card-text>
                <ol>
                  <li>
                    <strong>Avoidance:</strong>
                    <ul>
                      <li>Deciding not to use a particularly hazardous chemical in a manufacturing process.</li>
                      <li>
                        Choosing not to undertake a construction project in an area with extreme weather conditions.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Transfer:</strong>
                    <ul>
                      <li>Purchasing liability insurance for potential workplace accidents.</li>
                      <li>
                        Subcontracting high-risk tasks to specialized companies with better equipment or expertise.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Mitigation:</strong>
                    <ul>
                      <li>Installing safety guards on heavy machinery to reduce the risk of injuries.</li>
                      <li>
                        Implementing dust suppression systems in mining operations to minimize respiratory hazards.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Acceptance:</strong>
                    <ul>
                      <li>
                        Acknowledging that some level of noise is unavoidable in certain manufacturing processes, but
                        ensuring it's within legal limits.
                      </li>
                      <li>Accepting minor wear and tear on equipment as part of normal operations.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Diversification:</strong>
                    <ul>
                      <li>Using multiple suppliers for critical raw materials to reduce supply chain risks.</li>
                      <li>Developing various product lines to reduce dependence on a single market.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Monitoring:</strong>
                    <ul>
                      <li>Implementing real-time air quality monitoring systems in chemical plants.</li>
                      <li>
                        Using predictive maintenance techniques to monitor equipment health and prevent breakdowns.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Contingency Planning:</strong>
                    <ul>
                      <li>Developing emergency response plans for potential industrial accidents.</li>
                      <li>Having backup power systems in place for critical operations in case of power failures.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Training and Education:</strong>
                    <ul>
                      <li>
                        Providing comprehensive safety training for all employees, including proper use of Personal
                        Protective Equipment (PPE).
                      </li>
                      <li>Offering ongoing education on new safety protocols and equipment operation.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Documentation and Reporting:</strong>
                    <ul>
                      <li>Maintaining detailed logs of equipment maintenance and inspections.</li>
                      <li>Implementing a system for reporting and investigating near-misses and incidents.</li>
                    </ul>
                  </li>

                  <li>
                    <strong>Other:</strong>
                    <ul>
                      <li>Implementing a behavior-based safety program to encourage safe practices.</li>
                      <li>Using virtual reality simulations for high-risk training scenarios.</li>
                    </ul>
                  </li>
                </ol>
              </v-card-text>
            </v-card>
          </v-container>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Helpers from "@/mixins/helpers";
import { hazards } from "@/constants/hazardsData";
export default {
  name: "Assessment",
  mixins: [Helpers],
  computed: {
    allHazardSubTypes() {
      let v = [];
      this.hazards.forEach((h) => {
        h.subTypes.forEach((sub) => {
          v.push({ type: h.type, subType: sub, text: h.type + " " + sub, value: { type: h.type, subType: sub } });
        });
      });
      return v;
    },
  },
  watch: {
    // "steps[stepIndex].hazards[hazardIndex].subTypes[subTypeIndex].likelihood"(n, o) {
    //   console.log(n);
    //   if (n > o) {
    //     this.trackColor = "success";
    //   } else if (n < o) {
    //     this.trackColor = "error";
    //   }
    // },
  },
  data: () => ({
    dialogInfo: false,
    trackColor: "primary",
    // likelihoodIcons: ["😭", "😢", "☹️", "🙁", "😐", "🙂", "😊", "😁", "😄", "😍"],
    likelihoodThumbValue: ["Rare", "Unlikely", "Possible", "Likely", "Almost certain"],
    pullKey: 0,
    pushes: null,
    selectedItem: null,
    stepper: 1,
    showHazardList: null,
    assessment: false,
    hazardCollection: true,
    steps: [{ name: "", hazards: [], drawer: false }],
    riskControls: [
      "Avoidance",
      "Transfer",
      "Mitigation",
      "Acceptance",
      "Diversification",
      "Monitoring",
      "Contingency planning",
      "Training and education",
      "Documentation and reporting",
      "Other",
    ],
    hazards,
    selectedHazards: [],
    drawerHazardIndex: 0,
    hazardValues: {},
  }),

  methods: {
    setTrackColor(val) {
      if (val > 3) {
        return "red";
      } else if (val > 2) {
        return "orange";
      } else if (val > 1) {
        return "yellow";
      } else if (val > 0) {
        return "light-green";
      } else {
        return "light-blue";
      }
    },
    addHazard(hazard) {
      this.selectedHazards.push({ hazard });
    },
    addStep() {
      this.steps.push({ name: "", hazards: [], drawer: false });
    },
    formatHazards(stepCount) {
      let v = [];
      this.steps[stepCount].hazards.forEach((h) => {
        h.subTypes.forEach((sub) => {
          v.push({
            type: h.type,
            subType: sub.name,
            text: h.type + " " + sub.name,
            value: { type: h.type, subType: sub.name },
          });
        });
      });
      if (v) {
        this.hazardValues = v;
      }
    },
    setSelectHazard(stepCount, e) {
      this.steps[stepCount].hazards = [];
      if (this.steps[stepCount].hazards) {
        e.forEach((s) => {
          if (
            !this.steps[stepCount].hazards.find((h) => h.type == s.type)?.subTypes.some((sub) => sub.name == s.subType)
          ) {
            this.steps[stepCount].hazards.push({ stepCount: stepCount, type: s.type, subTypes: [] });
            const sub = { name: s.subType, impact: null, likelihood: null, actions: null, controls: null };
            this.steps[stepCount].hazards.find((h) => h.type == s.type)?.subTypes.push(sub);
          }
        });
      }
    },
    setHazard(stepCount, hazard, e) {
      //console.log(stepCount + "|" + hazard + "|" + typeCount + "|" + e);
      if (e) {
        //this.selectedHazards.push({ stepCount: stepCount, type: hazard, subTypes: [] });
        this.steps[stepCount].hazards.push({ stepCount: stepCount, type: hazard, subTypes: [] });
      } else {
        const idx = this.steps[stepCount].hazards.findIndex((h) => h.type == hazard);
        this.steps[stepCount].hazards.splice(idx, 1);
      }
    },
    setHazardType(stepCount, type, subType, e) {
      if (e) {
        const sub = { name: subType, impact: null, likelihood: null, actions: null, controls: null };
        this.steps[stepCount].hazards.find((h) => h.type == type)?.subTypes.push(sub);
      } else {
        const hazard = this.steps[stepCount].hazards.find((h) => h.type == type);
        const idx = hazard.subTypes?.findIndex((t) => t.name == subType);
        hazard.subTypes.splice(idx, 1);
      }
      this.formatHazards(stepCount);
    },
    assessHazards() {
      this.assessment = true;
      this.hazardCollection = false;
    },
    showSubTypes(hazard) {
      this.selectedHazards.push(hazard);
    },
    refresh() {},
    appRefresh() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          this.pullKey += 1;
        }, 1000);
      });
    },
    showAsset(type) {
      this.asset = type;
      if (type == "video") {
        this.$refs.videoPlayer.play();
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.step-input input {
  //font-size: 16px;
  //font-weight: bold;
  margin-top: 0 !important;
}
.v-slider__tick-label {
  font-size: 11px;
}
.v-slider--horizontal .v-slider__track-container {
  height: 4px;
}
</style>
